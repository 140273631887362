import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext('light');

export const ThemeProvider = ({ children }) => {

	let currentTheme;
	let htmlEl;

	if (typeof window !== 'undefined') {
		htmlEl = document.querySelector('html');
		currentTheme = localStorage.getItem('preferred-theme') || 'light';
	}

	const [themeType, setThemeType] = useState(currentTheme);

	const setTheme = (theme) => {
		localStorage.setItem('preferred-theme', theme);
		setThemeType(theme);
	};

	const handleTheme = () => {
		if(currentTheme === 'light') {
			setTheme('dark');
		} else if(currentTheme === 'dark') {
			setTheme('light');
		}
	};

	useEffect(() => {
		let themeClass;

		switch (themeType) {
			case 'light':
				themeClass = 'theme-canvas';
				break;
			case 'dark':
				themeClass = 'theme-dark';
				break;
			default:
			// do nothing
		}

		htmlEl.setAttribute('class', themeClass);
    
	}, [themeType]);

	return (
		<ThemeContext.Provider
			value={{
				themeType,
				handleTheme
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};

export default ThemeContext;